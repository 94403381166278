import React from 'react';
import { Link } from "gatsby"

function Breadcrumb({category_url, category, subcategory, subcategory_url, product}) {
  return (
    <div className="w-full pt-3">
      <div className="w-10/12 mx-auto max-w-screen-xl">
        <div className="font-poppins text-xs md:text-sm font-normal text-diamond">
          <Link to={`/${category_url}/`}>{category}</Link> 
          {subcategory_url && <>
            &nbsp;&nbsp;/&nbsp; <Link to={`/${category_url}/${subcategory_url}/`}>{subcategory}</Link></>
          } 
          &nbsp;&nbsp;/&nbsp; {product}
        </div>
      </div>
    </div>
  );
}

export default Breadcrumb;