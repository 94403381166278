import React from 'react';
import { useState, useContext } from 'react'
import CartContext from './cart_context';

function ProductQuantity({variantId, customSelections, formValues}) 
{ 
  //console.log('variantId: ', variantId);
  const [quantity, setQuantity] = useState(1);
  const {cartOpen, setCartOpen, updateLineItem} = useContext(CartContext)

  const handleShopifyQuantityChange = (e) => {
    setQuantity(e.currentTarget.value);
  };

  const handleShopifySubmit = (e) => {
    e.preventDefault();
    updateLineItem({
      variantId, 
      quantity: parseInt(quantity, 10),
      customSelections
    });
    setCartOpen(true);
  }

  return (
    <>
      <form onSubmit={handleShopifySubmit}>
        <div className="flex gap-3 items-center mb-6">
          <label htmlFor="qty" className="font-poppins font-medium text-base text-diamond-80">
            Quantity
          </label>
          <select onChange={handleShopifyQuantityChange} id="qty" name="qty" className="text-sm font-poppins font-medium block max-w-full border-2 border-diamond-50 py-1.5 leading-5 text-black text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-orange focus:border-orange">
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
          </select>
        </div>
        <button disabled={!formValues.formIsValid} type="submit" className="text-center inline-block bg-orange text-white font-poppins text-lg rounded-full px-6 py-3 font-medium hover:text-white transition-all duration-250 disabled:bg-diamond-10 disabled:text-diamond-80 disabled:cursor-not-allowed">
          <div className="flex justify-center items-center gap-1">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
              </svg>
            </div>
            <div>
              Add to cart
            </div>
          </div>
        </button>
      </form>
    </>
  );
}

export default ProductQuantity;