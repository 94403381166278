import React from 'react';
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { linkResolver } from '../utils/link_resolver'

function ProductSafety({data}) {
  return (
    <div className="w-full bg-diamond rounded-tr-[133px]">
      <div className="flex-none md:flex">
        <div className="w-full md:w-6/12">
          <GatsbyImage className="w-full h-full object-cover block py-12 md:py-0" image={data.safety_and_care_image.gatsbyImageData} alt={data.safety_and_care_image.alt ?? ''} /> 
        </div>
        <div className="w-full md:w-6/12">
          <div className="font-poppins p-12 max-w-none prose prose-h2:font-semibold prose-h2:text-white prose-h3:mt-0 prose-h3:mb-6 prose-h3:text-white prose-h3:font-semibold prose-li:text-white prose-p:text-white prose-p:font-medium">
            <h3>Product safety and care tips:</h3>
            <PrismicRichText linkResolver={linkResolver} field={data.safety_and_care.richText} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductSafety;