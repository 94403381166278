import * as React from "react"
import { graphql } from "gatsby"
import { Tab } from '@headlessui/react'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { linkResolver } from '../../../../utils/link_resolver'
import Seo from '../../../../components/seo'
import Footer from '../../../../components/footer'
import Header from '../../../../components/header'
import ProductQuantity from '../../../../components/product_quantity';
import ProductSafety from "../../../../components/product_safety"
import ProductCustomHeader from "../../../../components/product_custom_header"
import { useState, useContext, useEffect } from 'react'
import CartContext from '../../../../components/cart_context';
import Breadcrumb from "../../../../components/breadcrumb"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DogsSafetyLightsOrbilocServiceKit = ({data}) => {
  
  const entry = data.prismicDogsSafetyLightsOrbilocServiceKit

  const {getProductById} = useContext(CartContext);
  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [formValues] = useState({
    formErrors: '',
    formIsValid: true,
  });
  const [customSelections] = useState(null)

  useEffect(() => {
    getProductById(data.shopifyProduct.shopifyId).then(result => {
      //console.log('selectedVariant: ', selectedVariant);
      setProduct(result);
      if (result) {
        setSelectedVariant(
          result.variants.find(({ id }) => id === variantId) || result.variants[0]
        );
      }
    });
  }, [
    getProductById,
    setProduct,
    data.shopifyProduct.shopifyId,
    variantId,
    customSelections
  ]);

  const currency = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2
  })

  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />

      <Breadcrumb 
        category="Dogs" 
        category_url="dogs"
        subcategory="Safety Lights" 
        subcategory_url="safety-lights"
        product={entry.data.heading} 
      />

      <div className="w-full">
        <div className="w-10/12 mx-auto max-w-screen-xl py-16 pb-16">
          <div className="flex-none md:flex justify-between gap-24">
            <div className="w-full md:w-6/12 pb-12 md:py-0">
              <Tab.Group as="div" className="flex flex-col-reverse">
                <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                  <Tab.List className="grid grid-cols-8 gap-3">
                    {entry.data.images.map((entry, index) => (
                      <Tab key={`image_${index}`} className="relative flex h-12 cursor-pointer items-center justify-center focus:outline-none">
                        {({ selected }) => (
                          <>
                            <span className="absolute inset-0 overflow-hidden">
                              <GatsbyImage className="w-full h-full object-cover block" image={entry.image.gatsbyImageData} alt={entry.image.alt ?? ''} />
                            </span>
                            <span className={classNames(selected ? 'ring-orange' : 'ring-transparent','pointer-events-none absolute inset-0 ring-2 ring-offset-2')} aria-hidden="true"/>
                          </>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>
                <Tab.Panels className="aspect-w-1 aspect-h-1 w-full">
                  {entry.data.images.map((entry, index) => (
                    <Tab.Panel key={`image_${index}`}>
                      <GatsbyImage className="aspect-w-1 aspect-h-1 w-full h-full object-cover block" image={entry.image.gatsbyImageData} alt={entry.image.alt ?? ''} />
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            </div>
            <div className="w-full md:w-6/12 text-left">
              <h1 className="font-poppins text-orange font-semibold text-2xl lg:text-3xl leading-snug mb-6">
                {entry.data.heading}
              </h1>
              <div className="font-poppins prose leading-snug">
                <PrismicRichText linkResolver={linkResolver} field={entry.data.description.richText} />
              </div>
            </div>
          </div>
        </div>
      </div>
 
      <ProductCustomHeader props="Add to cart" />

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/cart_duo_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Add to cart</h2>
              </div>
              <div className="max-w-none prose font-poppins prose-p:text-diamond-80 prose-h2:font-semibold prose-h2:text-diamond prose-h2:mb-3 prose-p:font-medium leading-snug mb-12">
                <h2>Please check your selection</h2>
                <PrismicRichText linkResolver={linkResolver} field={entry.data.info.richText} />
              </div>

              {selectedVariant && (
                <div className="w-full pb-12">
                  <div className="font-poppins max-w-none font-semibold text-diamond text-4xl xl:text-6xl">
                    {selectedVariant.compareAtPriceV2 && selectedVariant.priceV2.amount < selectedVariant.compareAtPriceV2.amount ? (
                      <div className="flex flex-col lg:flex-row gap-0 lg:gap-6">
                        <div className="line-through">{currency.format(selectedVariant.compareAtPriceV2.amount)} NZD</div>
                        <div className="text-orange">{currency.format(selectedVariant.priceV2.amount)} NZD</div>
                      </div>
                    ) : (
                      <>
                        {currency.format(selectedVariant.priceV2.amount)} NZD
                      </>
                    )}
                  </div>
                </div>
              )}
              
              <div className="w-full">
                {!!selectedVariant && (
                  <>
                    {selectedVariant.available ? 
                      <ProductQuantity formValues={formValues} variantId={selectedVariant.id} customSelections={customSelections} />
                    : 
                    <div className="flex-1 border border-transparent py-3 px-8 flex items-center justify-center text-base text-white bg-red w-full">
                      Sold out
                    </div>
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProductSafety data={entry.data} />
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicDogsSafetyLightsOrbilocServiceKit {
    data {
      page_title
      meta_description
      heading
      description {
        richText
      }
      images {
        image {
          alt
          gatsbyImageData
        }
      }
      info {
        richText
      }
      safety_and_care_image {
        alt
        gatsbyImageData
      }
      safety_and_care {
        richText
      }
    }
  }
  shopifyProduct(handle: {eq: "dogs-safety-lights-orbiloc-service-kit"}) {
    id
    priceRangeV2 {
      maxVariantPrice {
        amount
      }
    }
    shopifyId
    storefrontId
    status
    variants {
      id
      price
      shopifyId
      storefrontId
      title
      availableForSale
    }
  }
}
`

export default DogsSafetyLightsOrbilocServiceKit