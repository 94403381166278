import React from 'react';

function ProductBuildYourOwn({props}) {
  return (
    <div className="w-full bg-orange py-12 mb-24">
      <div className="w-10/12 mx-auto max-w-screen-xl">
        <div className="text-center font-poppins font-semibold text-white text-2xl lg:text-4xl leading-snug">
          <h2>{props}</h2>
        </div>
      </div>
    </div>
  );
}

export default ProductBuildYourOwn;